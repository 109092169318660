<template>
  <a-modal
    width="50%"
    :title="$t('page.marketplace')"
    :visible="marketplaceModalShow"
    @ok="handleEditOk"
    @cancel="handleEditCancel"
  >
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <a-form
        :form="form"
        :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
        :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
      >
        <a-form-item
          :label="$t('field.marketplaceCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'marketplaceCode',
              {
                rules:$rules([
                  {required: true, message: '请输入' + this.$t('field.marketplaceCode') },
                  {len: 4, message: this.$t('field.marketplaceCode') + '长度需等于4' },
                  {type:'checkExists', existIdValue:marketplaceData.marketplaceId, checkField:'marketplaceCode', idFieldName: 'marketplaceId',tableName:'marketplace' }
                ]),
              },
            ]"
            v-model="marketplaceData.marketplaceCode"
            name="marketplaceData.marketplaceCode"
            :placeholder="'请输入' + this.$t('field.marketplaceCode')"
            :maxLength="4"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.marketplaceName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'marketplaceName',
              {
                rules: [
                  { required: true, message: '请输入' + this.$t('field.marketplaceName') },
                  { max: 20, message: this.$t('field.marketplaceName') + '长度小于等于20' },
                ],
              },
            ]"
            name="marketplaceData.marketplaceName"
            v-model="marketplaceData.marketplaceName"
            :placeholder="'请输入' + this.$t('field.marketplaceName')"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.country')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select
            mode="default"
            v-model="marketplaceData.country"
            v-decorator="[
              'country',
              {
                rules: [{ required: true, message: '请选择' + this.$t('field.country') }] }]"
          >
            <a-select-option
              v-for="(item, index) in codeList.country"
              :key="index"
              :value="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label="$t('field.language')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-select
            mode="multiple"
            v-model="marketplaceData.language"
            v-decorator="[
              'language',
              {
                rules: [{ required: true, message: '请选择' + this.$t('field.language'), type: 'array' }],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) in codeList.language"
              :key="index"
              :value="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-card>
  </a-modal>
</template>

<script>
import { saveMarketplaceData } from '@/api/system/marketplace'

export default {
  name: 'EditMarketplace',
  props: {},
  data () {
    return {
      form: this.$form.createForm(this),
      marketplaceModalShow: false,
      codeList: {},
      marketplaceData: {},
      countryColumns: [
        {
          defaultTitle: '语言',
          title: this.$t('columnName.language'),
          dataIndex: 'language',
          key: 'language',
          align: 'left',
          scopedSlots: { customRender: 'language' }
        },
        {
          defaultTitle: '操作',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    createForm () {
      let that = this
      this.$nextTick(() => {
        const formData = {}
        Object.keys(that.form.getFieldsValue()).map(key => (formData[key] = this.marketplaceData[key]))
        that.form.setFieldsValue(formData)
        console.log('初始化form', that.form)
      })
    },
    add () {
      this.marketplaceData = {
        countryList: []
      }
      this.createForm()
      this.marketplaceModalShow = true
    },
    edit (data) {
      this.marketplaceData = data
      this.createForm()
      // 弹出编辑页面
      this.marketplaceModalShow = true
    },
    handleEditCancel (record) {
      // 弹出编辑页面
      this.marketplaceModalShow = false
    },
    handleEditOk () {
      const that = this
      this.form.validateFieldsAndScroll((err, values) => {
        console.log('validateFieldsAndScroll', err, values)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        Object.assign({ ...that.marketplaceData, ...that.form.getFieldsValue() })
        saveMarketplaceData(this.marketplaceData)
          .then((res) => {
            that.$message.success(this.$t('save.entity.marketplace.success'))
            this.marketplaceData = {}
            this.marketplaceModalShow = false
            that.$parent.$parent.$refs.table.refresh(true)
          })
          .catch((res) => {
            that.$parent.$parent.$refs.table.refresh(true)
            that.$message.success(this.$t('save.entity.marketplace.fail'))
          })
      })
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
