<template>
  <page-header-wrapper>
    <div class="a-card">
      <MarketplaceSearch
        @resetData="resetSearchForm"
        @handleAdd="handleAdd"
        :queryParam="queryParam"
      />
      <a-button type="dashed" @click="tableOption">{{ (optionAlertShow && '关闭') || '开启' }} alert</a-button>
      <s-table
        :bordered="true"
        ref="table"
        :columns="columns"
        :dataSource="data"
        :alert="options.alert"
        :data="loadData"
        :rowSelection="options.rowSelection"
        rowKey="marketplaceId"
        :scroll="{ x: true}"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="language" slot-scope="text, record" style="display: flex;flex-wrap:wrap " > <a-tag v-for="(item, idx) in record.language" :key="idx" color="blue">
          {{ getCode('languageName', item) }}:{{ item }}
        </a-tag><br/>
        </span>
        <div slot="marketplaceCode" slot-scope="text,record">
          {{ record.marketplaceCode }}-
          {{ record.marketplaceName }}
        </div>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-dropdown>
            <a class="ant-dropdown-link"> 更多 <a-icon type="down"/> </a>
            <a-menu slot="overlay">
              <a-menu-item vv1-if="$auth('table.marketplace.disable')" v-if="record.status === 1">
                <a @click="handleDisable(record)">{{ $t('button.disable') }}</a>
              </a-menu-item>
              <a-menu-item vv1-if="$auth('table.marketplace.enable')" v-if="record.status === 0">
                <a @click="handleEnable(record)">{{ $t('button.enable') }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </s-table>
    </div>
    <MarketplaceEdit ref="editMarketplace"/>
    <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  deleteMarketplace,
  disableMarketplace,
  enableMarketplace,
  getMarketplaceData,
  saveMarketplaceData,
  searchMarketplace
} from '@/api/system/marketplace'
import { STable } from '@/components'
import MarketplaceEdit from '@/views/system/components/MarketplaceEdit'
import MarketplaceSearch from '@/views/system/MarketplaceSearch'
import CommentEditor from '@/components/comment/CommentEditor'

export default {
  name: 'MarketplaceList',
  components: {
    CommentEditor,
    STable,
    MarketplaceEdit,
    MarketplaceSearch
  },
  data () {
    return {
      confirmEditLoading: false,
      // 查询参数
      queryParam: {},
      data: [],
      status: 'all',
      columns: [
        {
          defaultTitle: '操作',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        },
        {
          defaultTitle: '国家',
          title: this.$t('columnName.country'),
          dataIndex: 'country',
          key: 'country',
          align: 'left'
        },
        {
          defaultTitle: '市场编码',
          title: this.$t('columnName.marketplaceCode'),
          dataIndex: 'marketplaceCode',
          key: 'marketplaceCode',
          align: 'left',
          'scopedSlots': {
            'customRender': 'marketplaceCode'
          }
        },
        {
          defaultTitle: '语言',
          title: this.$t('columnName.language'),
          dataIndex: 'language',
          key: 'language',
          align: 'left',
          scopedSlots: {
            customRender: 'language'
          }
        },
        {
          defaultTitle: '状态',
          title: this.$t('columnName.status'),
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          customRender: (text) => this.getCode('enableStatus', text)
        },
        {
          defaultTitle: '创建人',
          title: this.$t('columnName.creatorName'),
          dataIndex: 'creatorName',
          key: 'creatorName',
          align: 'left',
          ellipsis: true,
          scopedSlots: {
            customRender: 'creatorInfo'
          }
        },
        {
          defaultTitle: '更新人',
          title: this.$t('columnName.updaterName'),
          dataIndex: 'updaterName',
          key: 'updaterName',
          align: 'left',
          ellipsis: true,
          scopedSlots: {
            customRender: 'updaterInfo'
          }
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      marketplaceData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      }
    }
  },
  created () {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted () {
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchMarketplace(Object.assign(parameter, this.queryParam)).then((res) => {
        console.log('loadData->getMarketplaceList.parameter', parameter, res)
        return res
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleAdd (record) {
      // 弹出编辑页面
      this.$refs['editMarketplace'].add()
    },

    handleEdit (record) {
      const that = this
      getMarketplaceData({ marketplaceId: record.marketplaceId })
        .then((res) => {
          console.debug('getMarketplaceData', res)
          if (!res.countryList) {
            res.countryList = []
          }
          this.marketplaceData = res
          this.$refs['editMarketplace'].edit(this.marketplaceData)
        })
        .catch((res) => {
          that.$message.success(this.$t('get.entity.marketplace.fail'))
        })
    },
    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableMarketplace(record)
          .then((res) => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableMarketplace(record)
          .then((res) => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable (record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable (record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    handleDelete () {
      var that = this
      const params = {
        marketplaceId: this.selectedRows[0].marketplaceId
      }
      deleteMarketplace(params)
        .then((res) => {
          that.$message.success(this.$t('save.entity.marketplace.success'))
          this.$refs.table.refresh(true)
        })
        .catch((res) => {
          that.$message.success(this.$t('save.entity.marketplace.fail'))
        })
      this.selectedRowKeys = []
      this.selectedRows = []
      // 弹出编辑页面
      this.marketplaceModalShow = false
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      console.log(this.selectedRowKeys)
      this.selectedRows = selectedRows
    },
    resetSearchForm () {
      this.queryParam = {}
      this.$refs.table.refresh(true)
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
